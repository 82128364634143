<template>
  <Menubar :model="items">
    <template #start>
      <img
        alt="logo"
        @click="$router.push('/user/')"
        src="../../assets/logo.png"
        height="40"
        class="p-mr-2"
      />
    </template>
    <template #end>
      <Button
        label="تسجل خروج"
        @click="logout"
        icon="pi pi-power-off"
        class="p-button-danger"
      />
    </template>
  </Menubar>
</template>

<script>
import Menubar from 'primevue/menubar';
export default {
  components: {
    Menubar,
  },
  name: 'app-header',
  data() {
    return {
      items: [
        {
          label: 'الرئيسيه',
          icon: 'pi pi-fw pi-home',
          to: '/user',
        },
        {
          label: 'العقود',
          icon: 'pi pi-fw pi-book',
          to: '/user/contracts',
        },
        {
          label: 'عقود تحت التشغيل',
          icon: 'pi pi-fw pi-cloud-download',
          to: '/user/installations',
        },
        {
          label: 'عقود منتهيه',
          icon: 'pi pi-fw pi-cloud',
          to: '/user/delivery',
        },
      ],
    };
  },
  methods: {
    logout() {
      delete localStorage.user;
      location.reload();
    },
  },
};
</script>
